<script lang="ts" setup>
import { useMainStore } from "@/store/main/index";
const mainStore = useMainStore();

const i18nLocale = useI18n();
const locale = computed(() => i18nLocale.locale.value);

const setAxiosHeader = (): void => {
  if (window.location.pathname.startsWith("/ka")) {
    // axios.defaults.headers.common["lang"] = "en";
    mainStore.lang = "ka";
  } else if (window.location.pathname.startsWith("/ru")) {
    // axios.defaults.headers.common["lang"] = "ru";
    mainStore.lang = "ru";
  } else {
    // axios.defaults.headers.common["lang"] = "ka";
    mainStore.lang = "en";
  }
  // document.documentElement.lang = locale.value;
  // if(document !== undefined){

  //   document.documentElement.setAttribute("lang", locale.value);
  // }
};

onMounted(() => {
  setAxiosHeader();
});

watch(
  locale,
  (ns) => {
    if (!ns) return;
    mainStore.lang = ns as "en" | "ru" | "ka";
  },
  {
    immediate: true,
  }
);
</script>

<!-- No smoothscroll -->
<!-- <template>
    <slot />
</template> -->

<template>
  <!-- <SmoothScroll> -->
  <slot />
  <!-- </SmoothScroll> -->
</template>
